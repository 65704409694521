'use client';

import React from 'react';
import styles from './styles.module.css';
import { useUnit } from 'effector-react';
import { $arenaDataStore, $isSidebarHidden } from '@/app.model';
import classNames from 'classnames';
import {
    $selectedRadioStore,
    toggleRadio,
    $selectedCheckboxStore,
    toggleCheckbox,
    THorizontalPills,
    TPill,
    PillType,
} from './model';
import { Pill } from '../Pill';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';

export type TProps = {
    config: THorizontalPills;
    index: number;
    isPillsFromPages?: boolean;
};

const HorizontalPills = ({ config, index, isPillsFromPages = false }: TProps) => {
    const { pillType, horizontalGroup, pills, title, onSelect } = config;

    const selectedRadio = useUnit($selectedRadioStore);
    const selectedCheckboxes = useUnit($selectedCheckboxStore);
    const arenaData = useUnit($arenaDataStore);
    const selectCheckbox = useUnit(toggleCheckbox);
    const selectRadio = useUnit(toggleRadio);
    const { isDesktop } = useDeviceDetector();
    const isDesktopDetected = isDesktop();
    const isSidebarHidden = useUnit($isSidebarHidden);

    const handleChange = (pill: TPill) => {
        switch (pillType) {
            case PillType.Checkbox:
                selectCheckbox({ group: horizontalGroup, label: pill.label });
                break;
            case PillType.Radio:
                selectRadio({ group: horizontalGroup, label: pill.label });
                break;
            default:
                break;
        }

        if (onSelect) {
            onSelect(pill);
        }
    };

    const getSelectedState = (pill: TPill) => {
        if (pillType === PillType.Checkbox) {
            return selectedCheckboxes[horizontalGroup]?.has(pill.label);
        }

        if (pillType === PillType.Radio) {
            return selectedRadio[horizontalGroup] === pill.label;
        }

        if (pillType === PillType.Anchor) {
            return pill.isActive;
        }

        return false;
    };

    const containerClass = classNames(styles.horizontalPill, 'horizontalPills', {
        [styles.horizontalLinkNoTitle]: !title,
        [styles.pillsFromPages]: isPillsFromPages,
        [styles.pillsWithSidebar]: isDesktopDetected && !isSidebarHidden,
        [styles.pillsFromPagesWithSidebar]: isPillsFromPages && !isSidebarHidden,
    });

    return (
        <div className={containerClass}>
            {title && <p className={`text-20 ark-ui-title ${styles.title}`}>{title}</p>}
            {pills.map((pill, index) => {
                return (
                    <div key={index} className="ark-ui-pill-label">
                        <Pill
                            label={pill.label || ''}
                            pillType={pillType}
                            prefix={horizontalGroup}
                            href={pill.href || '#'}
                            target={pill.target || '_self'}
                            leftImg={pill.leftImg}
                            rightImg={pill.rightImg}
                            override_border_color={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.border_color || ''
                            }
                            override_border_color_hover={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.border_color_hover || ''
                            }
                            override_border_color_focus={''}
                            override_border_color_active={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.border_color_active || ''
                            }
                            override_label_color={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.label_color || ''
                            }
                            override_label_color_hover={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.label_color_hover || ''
                            }
                            override_label_color_focus={''}
                            override_label_color_active={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.label_color_active || ''
                            }
                            override_background_color={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.background_color || ''
                            }
                            override_background_color_hover={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.background_color_hover || ''
                            }
                            override_background_color_focus={''}
                            override_background_color_active={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.background_color_active || ''
                            }
                            onChange={() => handleChange(pill)}
                            selected={getSelectedState(pill)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default HorizontalPills;
